import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          sevenawesome.srl@gmail.com
          <br/>
          +1 809-409-7195
        </p>
 
      </header>
    </div>
  );
}

export default App;
